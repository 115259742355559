<template>
	<div>
		<div class="new-bottom">
			<div class="down_pc"
				><img
					class="down_img"
					src="https://res.metaera.hk/resources/assets/images/active/down_pc_new.png"
			/></div>
			<shortBottom></shortBottom>
		</div>
		<!-- <div class="down_pc">
			<img
				class="down_bg"
				src="https://res.metaera.hk/resources/assets/images/aboutus/down-bg.png"
			/>
			<div class="btn-box">
				<div class="btn btn-ios">
					<img
						src="https://res.metaera.hk/resources/assets/images/aboutus/download_ios.png"
						alt=""
					/>
					<span>iOS</span>
				</div>
				<div class="btn btn-android">
					<img
						src="https://res.metaera.hk/resources/assets/images/aboutus/download_android_w.svg"
						alt=""
					/>
					<span>Android</span>
				</div>
			</div>
		</div> -->
		<div class="dl_main down_mobile">
			<div class="dl_title">
				<p>Download</p>
				<p>MetaEra App</p>
			</div>
			<div class="dl_ios">
				<img
					src="https://res.metaera.hk/resources/assets/images/aboutus/download_ios.png"
					alt=""
				/>
				<div>iOS</div>
			</div>
			<div class="dl_android">
				<img
					src="https://res.metaera.hk/resources/assets/images/aboutus/download_android.png"
					alt=""
				/>
				<div>Android</div>
			</div>
			<div class="dl_img">
				<img
					src="https://res.metaera.hk/resources/assets/images/aboutus/download.png"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
import shortBottom from "@/components/pc/shortBottom.vue";
export default {
	props: ["item"],
	components: {
		shortBottom,
	},
	data() {
		return {
			allData: [],
		};
	},

	methods: {},
};
</script>

<style scoped>
.new-bottom {
	padding-bottom: 40px;
	width: clamp(300px, calc(100vw * 380 / 1440), 380px);
}
.down_pc {
	position: relative;
	width: clamp(200px, calc(100vw * 380 / 1440), 380px);
	height: auto;
	border-radius: calc(100vw * 15 / 1440);
	overflow: hidden;
	display: block;
}
.down_pc .down_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.down_mobile {
	display: none !important;
}
.down_pc .down_bg {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.down_pc .btn-box {
	position: absolute;
	right: calc(100vw * 35 / 1440);
	bottom: calc(100vw * 40 / 1440);
}
.down_pc .btn {
	display: flex;
	align-items: center;
	margin-bottom: 14px;
	width: calc(100vw * 97 / 1440);
	height: calc(100vw * 28 / 1440);
	border-radius: 5px;
	background: #333;
	padding-left: calc(100vw * 12 / 1440);
}
.down_pc .btn-android {
	background: #fff;
}
.btn span {
	font-size: calc(100vw * 14 / 1440);
	color: #ffffff;
}
.btn-android span {
	color: #333;
}
.btn img {
	width: calc(100vw * 12 / 1440);
	height: calc(100vw * 12 / 1440);
	margin-right: calc(100vw * 5 / 1440);
}
@media screen and (max-width: 900px) {
	.down_pc {
		display: none;
	}
	.down_mobile {
		display: block;
	}
}
.dl_main {
	display: flex;
	margin-top: 30px;
	top: 30px;
	width: 290px;
	height: 100px;
	border-radius: 10px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
}
.dl_title {
	position: absolute;
	margin-left: 10px;
	margin-top: 16px;
}
.dl_title p {
	font-family: PingFang SC !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	line-height: 18px;
	letter-spacing: 0em;
	/* 纯黑 */
	color: #000000;
}

.dl_ios,
.dl_android {
	display: flex;
	justify-content: center;
	margin-top: 59px;
	margin-left: 10px;
	width: 54px;
	height: 24px;
	border-radius: 5px;
	opacity: 1;
	/* 主色（一级颜色） */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);

	align-items: center;
}

.dl_android {
	width: 86px;
	background: #333333;
}
.dl_ios img,
.dl_android img {
	width: 12px;
	height: 12px;
}
.dl_ios div,
.dl_android div {
	margin-left: 4px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;
	/* 纯白 */
	color: #ffffff;
}

.dl_img {
	margin-left: 50px;
	margin-top: 15px;
	width: 68px;
	height: 68px;
	opacity: 1;
}
.dl_img img {
	width: 100%;
	height: 100%;
}
</style>
